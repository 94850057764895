import React, { useState } from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as WebBrowser from 'expo-web-browser';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@hamlet/utils';
import * as Animatable from 'react-native-animatable';

import { RootRoutes } from '../../../common/routes/routes';
import { RootStackParamList } from '../../../common/routes/types';
import { useToast } from '../../../common/hooks/useToast';
import { useUserState } from '../../../common/state';
import { useAuth0 } from '../../../common/hooks/auth0';
import { tw } from '../../../common/styles/tailwind';
import {
  getCalendarData,
  getHouseDataByHouseId,
  getUserData,
  getUserLimits,
} from '../../../common/utils/utils';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { isWeb } from '../../../common/constants';

WebBrowser.maybeCompleteAuthSession();

export const LoginScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { login } = useAuth0();
  const { setUserInfo, setHouseInfo, setCalendarData, setUserLimits, setAccessToken } =
    useUserState();
  const { setItem: setToken } = useAsyncStorage('jwtToken');
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();

  const setInitialData = async (userId: string, houseId: string | null, accessToken: string) => {
    const limitStatus = await getUserLimits(userId, accessToken);

    if (limitStatus) {
      setUserLimits(limitStatus);
    }

    if (!houseId) {
      return;
    }

    const houseInfo = await getHouseDataByHouseId(houseId, accessToken);
    const calendarData = await getCalendarData(houseId, userId, accessToken);

    if (calendarData) {
      setCalendarData(calendarData);
    }

    if (houseInfo) {
      setHouseInfo(houseInfo);
    }
  };

  const handleAuth = async () => {
    setIsLoading(true);
    try {
      const { tokenData, accessToken, tokenConfig } = await login();

      const userData = await getUserData(tokenData.userId, accessToken);

      await setToken(tokenConfig);
      setAccessToken(accessToken);
      if (userData) {
        setUserInfo(userData);
        await setInitialData(userData.id, userData.house_id, accessToken);
      }

      reset({ index: 0, routes: [{ name: RootRoutes.ROOT }] });
      toast.success(SUCCESS_MESSAGES.login);
    } catch (err) {
      toast.danger(ERROR_MESSAGES.default);
    }
    setIsLoading(false);
  };

  return (
    <Animatable.View style={tw`h-full bg-white`} useNativeDriver duration={1000} animation="fadeIn">
      <SafeAreaView style={tw`w-full p-5 h-full bg-white`}>
        {isLoading && <ScreenLoader />}
        <View style={tw`items-center justify-center flex flex-col flex-1 h-full w-full`}>
          <View style={tw`w-[100%] h-[50%]`}>
            <Image
              source={require('../../../../assets/hamlet.png')}
              resizeMode="contain"
              style={tw`w-[100%] h-[100%]`}
            />
            <Text style={tw`font-base text-xl text-center`}>Your dream, your holiday home</Text>
            <Text style={tw`font-base text-xl text-center`}>- only smarter</Text>
          </View>

          <View style={tw`h-[40%] w-full flex justify-end items-center`}>
            <TouchableOpacity
              style={tw`px-6 py-4 justify-center ${
                isWeb ? 'min-w-[180px] w-full max-w-[40%]' : 'w-full'
              } rounded-lg bg-primary`}
              onPress={handleAuth}
              activeOpacity={0.6}
            >
              <Text style={tw`text-lg text-white font-header font-bold text-center`}>
                Login / Signup
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </Animatable.View>
  );
};
