import React from 'react';
import { View, Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';

const BookingRulesElement = ({
  title,
  titleTag,
  description,
}: {
  title: string;
  titleTag?: string;
  description: string;
}) => {
  return (
    <View style={tw`flex flex-col my-3 bg-white shadow-md p-3 rounded-md`}>
      <View style={tw`flex flex-row items-center`}>
        <View style={tw`flex flex-1`}>
          <View style={tw`flex flex-row justify-between items-center mb-3`}>
            <Text style={tw`text-lg font-bold mr-4 font-header`}>{title}</Text>
            {titleTag && (
              <View style={tw`bg-primary w-30 py-2 flex items-center justify-center rounded-full`}>
                <Text style={tw`font-bold text-md text-white font-header`}>{titleTag}</Text>
              </View>
            )}
          </View>
          <Text style={tw`text-md font-header leading-5`}>{description}</Text>
        </View>
      </View>
    </View>
  );
};

export default BookingRulesElement;
