import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { DEFAULT_HEADER_OPTIONS } from '../constants';
import { ProfileStackParamList } from '../types';
import { ProfileRoutes } from '../routes';
import { ProfileScreen } from '../../../domain/profile/screens/Profile';
import { ContactInformationScreen } from '../../../domain/profile/screens/ContactInformation';
import { EditInformation } from '../../../domain/profile/screens/EditInformation';
import { AboutApp } from '../../../domain/profile/screens/AboutApp';

const Stack = createStackNavigator<ProfileStackParamList>();

export const ProfileStackNav = () => (
  <Stack.Navigator initialRouteName={ProfileRoutes.PROFILE}>
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={ProfileRoutes.PROFILE}
      component={ProfileScreen}
    />
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={ProfileRoutes.CONTACT_INFORMATION}
      component={ContactInformationScreen}
    />
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={ProfileRoutes.EDIT_INFORMATION}
      component={EditInformation}
    />
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={ProfileRoutes.ABOUT_APP}
      component={AboutApp}
    />
  </Stack.Navigator>
);
