import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import Fontisto from 'react-native-vector-icons/Fontisto';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Layout } from '../../../common/components/Layout';
import { ModalSectionElement } from '../../../common/components/ModalSectionElement';
import { tw } from '../../../common/styles/tailwind';
import { YourBookingsExample, SpecialDateExample, PeakSeasonExample } from './BookingsExamples';

const CalendarHelpModal = ({
  isInfoModalVisible,
  closeModal,
  openStayRules,
}: {
  isInfoModalVisible: boolean;
  closeModal: (value: boolean) => void;
  openStayRules: () => void;
}) => {
  return (
    <CustomBottomSheetModal
      closeModal={() => closeModal(false)}
      isVisible={isInfoModalVisible}
      snapPoints={['50%', '95%']}
      title="Calendar Help"
    >
      <Layout
        scrollable
        contentContainerStyle={tw`pb-[30px]`}
        commonStyles="px-4"
        webStyles="mt-10"
      >
        <ModalSectionElement
          icon={<Fontisto size={26} name="date" color="black" />}
          title="Your booked stays"
          description="Each of your booked stays is highlighted in green color, starting with your check-in date and
              ending with your check-out date"
          example={<YourBookingsExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="progress-star" color="black" />}
          title="Special dates"
          description="These include federal holidays and notable local events. You can hold 1 special date
              at a time, and it counts toward your general stays."
          example={<SpecialDateExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="chart-bell-curve" color="black" />}
          title="Peak booking"
          description="The peak season opens for booking 24 months in advance. The peak season is
              highlighted with a distinct color"
          example={<PeakSeasonExample />}
        />
        <ModalSectionElement
          icon={<MaterialCommunityIcons size={26} name="calendar-clock-outline" color="black" />}
          title="Short notice"
          description="Calendar dates 2 - 30 days in advance. Stays within this timeframe will not count toward your stay count."
        />
        <TouchableOpacity
          onPress={openStayRules}
          style={tw`flex items-center bg-primary rounded-md mt-5`}
          activeOpacity={0.8}
        >
          <Text style={tw`text-lg font-bold text-white p-2 font-header`}>Learn more</Text>
        </TouchableOpacity>
      </Layout>
    </CustomBottomSheetModal>
  );
};
export default CalendarHelpModal;
