import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RouteProp, useRoute } from '@react-navigation/native';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '@hamlet/utils';
import { useUpdateUserByUserIdMutation } from '@hamlet/graphql-urql';

import { tw } from '../../../common/styles/tailwind';
import { ProfileStackParamList } from '../../../common/routes/types';
import { ProfileRoutes } from '../../../common/routes/routes';
import { UserFieldNames } from '../types';
import { EditNameForm } from '../components/Forms/EditNameForm';
import { SettingsForm } from '../components/Forms/SettingsForm';
import { PhoneForm } from '../components/Forms/PhoneForm';
import { LocationForm } from '../components/Forms/LocationForm';
import { FormFieldsData } from '../../../common/types/types';
import { useToast } from '../../../common/hooks/useToast';
import { useUserState } from '../../../common/state';

export const getBodyComponent = (
  name: UserFieldNames,
  handleSave: (user: FormFieldsData, resetFields: (user: FormFieldsData) => void) => Promise<void>
): JSX.Element => {
  const forms: { [key in UserFieldNames]: JSX.Element } = {
    [UserFieldNames.NAME]: <EditNameForm handleSave={handleSave} />,
    [UserFieldNames.PHONE]: <PhoneForm handleSave={handleSave} />,
    [UserFieldNames.COUNTRY]: <LocationForm handleSave={handleSave} />,
    [UserFieldNames.SETTINGS]: <SettingsForm handleSave={handleSave} />,
  };

  return forms[name];
};

export const EditInformation = () => {
  const toast = useToast();
  const { userInfo, setUserInfo } = useUserState();
  const [, updateUserByUserId] = useUpdateUserByUserIdMutation();
  const { params } = useRoute<RouteProp<ProfileStackParamList, ProfileRoutes.EDIT_INFORMATION>>();

  const handlePress = async (user: FormFieldsData, resetFields: (user: FormFieldsData) => void) => {
    if (!userInfo) {
      toast.danger(ERROR_MESSAGES.default);
      return;
    }

    const { data } = await updateUserByUserId({
      id: userInfo.id,
      user,
    });

    if (!data?.update_users_by_pk) {
      toast.danger(ERROR_MESSAGES.default);
      return;
    }

    setUserInfo(data?.update_users_by_pk);
    resetFields(user);
    toast.success(SUCCESS_MESSAGES.modified);
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`}>
      {getBodyComponent(params.name, handlePress)}
    </SafeAreaView>
  );
};
