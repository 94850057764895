export enum RootRoutes {
  LOGIN = 'LOGIN',
  ROOT = 'ROOT',
  STAY_DETAILS = 'STAY_DETAILS',
  MODIFY_MY_STAY = 'MODIFY_MY_STAY',
  NOT_FOUND = 'NOT_FOUND',
}

export enum RootTabRoutes {
  HOME_ROOT = 'HOME_ROOT',
  BOOK_ROOT = 'BOOK_ROOT',
  STAYS_ROOT = 'STAYS_ROOT',
  PROFILE_ROOT = 'PROFILE_ROOT',
}

export enum StayRoutes {
  STAYS = 'STAYS',
}

export enum HomeRoutes {
  HOME = 'HOME',
}

export enum BookRoutes {
  BOOK = 'BOOK',
}

export enum ProfileRoutes {
  PROFILE = 'PROFILE',
  CONTACT_INFORMATION = 'CONTACT_INFORMATION',
  EDIT_INFORMATION = 'EDIT_INFORMATION',
  ABOUT_APP = 'ABOUT_APP',
}
