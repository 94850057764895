import classNames from 'classnames';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';

import { tw } from '../styles/tailwind';

interface Props {
  containerStyle?: string;
  onPress: () => void;
}

const HelpButton: React.FC<Props> = ({ containerStyle = '', onPress }) => {
  const containerStyles = classNames(
    'bg-red-200 w-15 h-15 z-50 flex items-center justify-center rounded-full shadow-lg bg-primary absolute bottom-10 right-8',
    containerStyle
  );

  return (
    <TouchableOpacity style={tw`${containerStyles}`} onPress={onPress} activeOpacity={0.8}>
      <SimpleLineIcons name="question" color="white" size={24} />
    </TouchableOpacity>
  );
};

export default HelpButton;
