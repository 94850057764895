/* eslint-disable react-native/no-inline-styles */
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import { TokenResponseConfig } from 'expo-auth-session';
import * as Font from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useState } from 'react';
import { Dimensions, Image } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Animatable from 'react-native-animatable';

import { Messages } from './src/common/components/Messages';
import { getActualTokenData } from './src/common/hooks/auth0';
import { UrqlProvider } from './src/common/providers/UrqlProvider';
import { Navigator } from './src/common/routes/AppNavigator';
import { useUserState } from './src/common/state';
import {
  getCalendarData,
  getHouseDataByHouseId,
  getUserData,
  getUserLimits,
} from './src/common/utils/utils';
import { Asset } from 'expo-asset';
import { isWeb } from './src/common/constants';

const { width, height } = Dimensions.get('window');

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
// @ts-ignore
if (process.browser) {
  // @ts-ignore
  window._frameTimestamp = null;
}

function cacheImages(images: any) {
  return images.map((image: any) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

function cacheFonts(fonts: any) {
  return fonts.map((font: any) => Font.loadAsync(font));
}

export const App = () => {
  const setAccessToken = useUserState((state) => state.setAccessToken);
  const setUserInfo = useUserState((state) => state.setUserInfo);
  const setHouseInfo = useUserState((state) => state.setHouseInfo);
  const setCalendarData = useUserState((state) => state.setCalendarData);
  const setUserLimits = useUserState((state) => state.setUserLimits);
  const [isReady, changeIsReady] = useState(false);
  const [fontAndImagesReady, setFontAndImagesReady] = useState(false);
  const { getItem: getCachedToken, setItem: setToken } = useAsyncStorage('jwtToken');

  const [fontsLoaded] = Font.useFonts({
    Ballpoint: require('./assets/fonts/Ballpoint-Regular.otf'),
    Feijoa: require('./assets/fonts/Feijoa-Medium.otf'),
    Styrene: require('./assets/fonts/StyreneB-Regular.otf'),
  });

  const setInitialData = useCallback(
    async (userId: string, houseId: string | null, accessToken: string) => {
      const limitStatus = await getUserLimits(userId, accessToken);

      if (limitStatus) {
        setUserLimits(limitStatus);
      }

      if (!houseId) {
        return;
      }

      const houseInfo = await getHouseDataByHouseId(houseId, accessToken);
      const calendarData = await getCalendarData(houseId, userId, accessToken);

      if (calendarData) {
        setCalendarData(calendarData);
      }

      if (houseInfo) {
        setHouseInfo(houseInfo);
      }
    },
    [setHouseInfo, setCalendarData, setUserLimits]
  );

  const setInitialDataFromToken = useCallback(async () => {
    try {
      const tokenString = await getCachedToken();

      if (!tokenString) {
        return;
      }

      const oldTokenConfig: TokenResponseConfig = JSON.parse(tokenString);

      if (!oldTokenConfig) {
        return;
      }

      const { tokenConfig, tokenData, accessToken } = await getActualTokenData(oldTokenConfig);

      const userData = await getUserData(tokenData.userId, accessToken);

      if (userData) {
        setUserInfo(userData);
        await setInitialData(userData.id, userData.house_id, accessToken);
      }

      setToken(tokenConfig);
      setAccessToken(accessToken);
    } finally {
      changeIsReady(true);
    }
  }, [getCachedToken, setAccessToken, setToken, setUserInfo, setInitialData]);

  useEffect(() => {
    setInitialDataFromToken();
  }, [setInitialDataFromToken]);

  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        const imageAssets = cacheImages([require('./assets/hamlet.png')]);

        const fontAssets = cacheFonts([
          require('./assets/fonts/Ballpoint-Regular.otf'),
          require('./assets/fonts/Feijoa-Medium.otf'),
          require('./assets/fonts/StyreneB-Regular.otf'),
        ]);

        await Promise.all([...imageAssets, ...fontAssets]);
      } catch (e) {
      } finally {
        setFontAndImagesReady(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  if (!isReady || !fontAndImagesReady || !fontsLoaded) {
    return (
      <Animatable.View
        useNativeDriver
        duration={1000}
        animation="fadeIn"
        style={{
          flex: 1,
          width: width,
          height: height,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          source={require('./assets/hamlet.png')}
          resizeMode="contain"
          style={isWeb ? { width: '50%', height: '50%' } : { width: '100%', height: '100%' }}
        />
      </Animatable.View>
    );
  }

  return (
    <UrqlProvider>
      <BottomSheetModalProvider>
        <SafeAreaProvider>
          <StatusBar style="dark" />
          <Messages />
          {isReady && <Navigator />}
        </SafeAreaProvider>
      </BottomSheetModalProvider>
    </UrqlProvider>
  );
};
