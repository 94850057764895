import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { tw } from '../styles/tailwind';
import { RootTabRoutes } from '../routes/routes';
import { ButtonType, HeaderLeft } from './HeaderLeftButton';
import { RootTabParamList } from '../routes/types';
import { NavigationHeaderButton } from './NavigationHeaderButton';
import { isWeb } from '../constants';

interface HeaderProps {
  title: string;
  leftButtonType?: ButtonType;
  rightIcon?: JSX.Element;
  disabledRightButton?: boolean;
  showNavigationButtons?: boolean;
  showAllButtons?: boolean;
  showRightIcon?: boolean;
  activeButtonRoute?: RootTabRoutes;
  onPressRightIcon?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  leftButtonType,
  rightIcon,
  disabledRightButton = false,
  showNavigationButtons = false,
  showAllButtons = false,
  showRightIcon = !isWeb,
  activeButtonRoute = RootTabRoutes.HOME_ROOT,
  onPressRightIcon,
}) => {
  const { navigate } = useNavigation<StackNavigationProp<RootTabParamList>>();

  const headerStyle = 'p-5 flex items-center flex-row justify-between';

  return (
    <View
      style={
        isWeb ? tw`${headerStyle} border-b-1 border-neutral-400 shadow-md mb-3` : tw`${headerStyle}`
      }
    >
      <View style={tw`max-w-web w-full mx-auto flex-row justify-between`}>
        {leftButtonType && (
          <View>
            <HeaderLeft type={leftButtonType} />
          </View>
        )}
        <Text style={tw`text-black text-xl font-header`}>{title}</Text>
        <View style={tw`flex-row items-center`}>
          {rightIcon && showRightIcon && (
            <TouchableOpacity
              disabled={disabledRightButton}
              onPress={onPressRightIcon}
              activeOpacity={0.8}
            >
              {rightIcon}
            </TouchableOpacity>
          )}

          {showNavigationButtons && (
            <View style={tw`flex-row`}>
              <NavigationHeaderButton
                activeButtonRoute={activeButtonRoute}
                type={RootTabRoutes.HOME_ROOT}
                onPress={navigate}
              />
              {showAllButtons && (
                <>
                  <NavigationHeaderButton
                    activeButtonRoute={activeButtonRoute}
                    type={RootTabRoutes.BOOK_ROOT}
                    onPress={navigate}
                  />
                  <NavigationHeaderButton
                    activeButtonRoute={activeButtonRoute}
                    type={RootTabRoutes.STAYS_ROOT}
                    onPress={navigate}
                  />
                </>
              )}
              <NavigationHeaderButton
                activeButtonRoute={activeButtonRoute}
                type={RootTabRoutes.PROFILE_ROOT}
                onPress={navigate}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
