import React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as WebBrowser from 'expo-web-browser';

import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { tw } from '../../../common/styles/tailwind';
import { SettingsItem } from '../components/SettingsItem';
import { ProfileIconNames } from '../types';
import { HorizontalLine } from '../components/HorizontalLine';
import { Layout } from '../../../common/components/Layout';

interface Props {}

export const AboutApp: React.FC<Props> = () => {
  const openWebLink = async (link: string) => {
    try {
      await WebBrowser.openBrowserAsync(link);
    } catch (error) {}
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`}>
      <Header leftButtonType={ButtonType.BACK} title="About App" />
      <Layout scrollable webStyles="shadow-lg">
        <SettingsItem
          title="Privacy Policy"
          iconName={ProfileIconNames.PRIVACY}
          onPress={() =>
            openWebLink(
              'https://docs.google.com/document/d/1avzY-PZjU9dIQ_U79sNVZJS56VDF2fIBPFKxGZSD20g/edit?usp=sharing'
            )
          }
        />
        <HorizontalLine />
        <SettingsItem
          title="Terms of use"
          iconName={ProfileIconNames.TERMS}
          onPress={() =>
            openWebLink(
              'https://docs.google.com/document/d/1LR91p3ztoh_RVIoQSZNRtf26qujfTgtK3egLqdCj9ug/edit?usp=sharing'
            )
          }
        />
        <HorizontalLine />
      </Layout>
    </SafeAreaView>
  );
};
