import {
  CalendarDataOutlet,
  GetHouseByIdQuery,
  GetUserByIdQuery,
  LimitStatus,
} from '@hamlet/graphql-urql';
import { IMessage, IMessageOptions } from '../components/Messages';

export interface UserInfo {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  locale: string;
  name: string;
  nickname: string;
  picture: string;
  sid: string;
  sub: string;
  updated_at: string;
  userId?: string;
  houseId?: string;
}

export interface IMessageState {
  message: IMessage | null;
  setInfoMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setSuccessMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setWarningMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  setDangerMessage: (message: string, description?: string, options?: IMessageOptions) => void;
  clearMessage: () => void;
}

export interface IUserState {
  accessToken: string;
  userInfo: GetUserByIdQuery['users_by_pk'];
  houseInfo: GetHouseByIdQuery['houses_by_pk'];
  calendarData: CalendarDataOutlet;
  userLimits: LimitStatus | null;
  setHouseInfo: (data: GetHouseByIdQuery['houses_by_pk']) => void;
  setAccessToken: (token: string) => void;
  setUserInfo: (data: GetUserByIdQuery['users_by_pk']) => void;
  deleteUserInfo: () => void;
  setCalendarData: (data: CalendarDataOutlet) => void;
  setUserLimits: (data: LimitStatus) => void;
}

export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}
