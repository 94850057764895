import React from 'react';
import { Pressable, Text, View } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import classNames from 'classnames';

import { tw } from '../../../common/styles/tailwind';
import { ProfileIconNames } from '../types';

interface Props {
  title: string;
  description?: string;
  iconName?: ProfileIconNames;
  onPress: () => void;
}

export const SettingsItem: React.FC<Props> = ({ title, description, iconName, onPress }) => {
  const infoWrapper = classNames({
    'justify-center': !description,
    'justify-between': description,
  });

  return (
    <Pressable style={tw`p-4 flex-row justify-between items-center`} onPress={onPress}>
      <View style={tw`${infoWrapper}`}>
        <Text style={tw`text-base text-gray-800 font-header`}>{title}</Text>
        {description && <Text style={tw`text-sm text-gray-600 font-header`}>{description}</Text>}
      </View>
      {iconName && <MaterialIcons name={iconName} color="#6a6969" size={26} />}
    </Pressable>
  );
};
