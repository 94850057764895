import { View, Text } from 'react-native';
import { tw } from '../../../common/styles/tailwind';

const defaultTextStyle = 'text-white mr-4 text-md';
const blackText = `${defaultTextStyle} text-black`;
const roundedCalendarStyle = 'rounded-r-3xl w-40 rounded-l-3xl border-l-1 ml-1 border-r-1 mr-1';

const commonStyle = 'h-10 w-40 mt-4 items-center justify-center flex flex-row';

export const YourBookingsExample = () => {
  return (
    <View style={tw`${commonStyle} ${roundedCalendarStyle} bg-bookedDate`}>
      <Text style={tw`${defaultTextStyle}`}>24</Text>
      <Text style={tw`${defaultTextStyle}`}>25</Text>
      <Text style={tw`${defaultTextStyle}`}>26</Text>
      <Text style={tw`${defaultTextStyle} mr-0`}>27</Text>
    </View>
  );
};

export const SpecialDateExample = () => {
  return (
    <View style={tw`${commonStyle} ${roundedCalendarStyle} border-black border-t-1 border-b-1`}>
      <Text style={tw`${blackText}`}>19</Text>
      <Text style={tw`${blackText}`}>20</Text>
      <Text style={tw`${blackText}`}>21</Text>
      <Text style={tw`${blackText} mr-0`}>22</Text>
    </View>
  );
};

export const PeakSeasonExample = () => {
  return (
    <View style={tw`${commonStyle} bg-secondary`}>
      <Text style={tw`${blackText}`}>10</Text>
      <Text style={tw`${blackText}`}>11</Text>
      <Text style={tw`${blackText}`}>12</Text>
      <Text style={tw`${blackText} mr-0`}>13</Text>
    </View>
  );
};
