import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StackNavigationProp } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';

import { RootStackParamList } from '../routes/types';
import { RootRoutes } from '../routes/routes';
import { Header } from '../components/Header';
import { tw } from '../styles/tailwind';
import { Layout } from '../components/Layout';

export const NotFoundScreen = () => {
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();

  const handleNavigate = () => {
    reset({ index: 0, routes: [{ name: RootRoutes.ROOT }] });
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`}>
      <Header title="Not Found" />
      <Layout scrollable={true} withShadow>
        <View style={tw`items-center py-5`}>
          <Text style={tw`text-2xl font-header`}>This screen doesnt exist</Text>
          <TouchableOpacity onPress={handleNavigate} activeOpacity={0.8}>
            <Text style={tw`text-primary font-bold font-header pt-4`}>GO TO HOME SCREEN</Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </SafeAreaView>
  );
};
