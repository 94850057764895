import Constants from 'expo-constants';

interface Config {
  hasuraUri: string;
  auth0Domain: string;
  auth0ClientId: string;
}

const getConfig = (): Config => ({
  hasuraUri:
    process.env.NEXT_PUBLIC_HASURA_GRAPHQL_URL || Constants?.expoConfig?.extra?.hasuraUri || '',
  auth0Domain: process.env.AUTH0_DOMAIN || Constants?.expoConfig?.extra?.auth0Domain || '',
  auth0ClientId: process.env.AUTH0_CLIENT_ID || Constants?.expoConfig?.extra?.auth0ClientId || '',
});

const config = getConfig();

export default config;
