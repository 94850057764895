import React from 'react';
import classNames from 'classnames';
import { View, Text, TouchableOpacity } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { SelectedDates, Stay_Types_Enum } from '@hamlet/graphql-urql';

import { tw } from '../../../common/styles/tailwind';
import { PolicyInfoTypes } from '../types';

interface Props {
  type: PolicyInfoTypes;
  title: string;
  description: string;
  selected: SelectedDates;
  onPress: (type: PolicyInfoTypes) => void;
}

export const PolicyInfo: React.FC<Props> = ({ type, title, description, onPress }) => {
  const containerStyles = classNames(
    'w-full max-w-3xl justify-between border-gray-300 pb-4 items-center flex flex-row mb-4',
    {
      'border-b-1': type === Stay_Types_Enum.PeakSeasonStay,
    }
  );

  const handlePress = () => {
    onPress(type);
  };

  return (
    <View style={tw`${containerStyles}`}>
      <View style={tw`w-10/12`}>
        <Text style={tw`font-bold font-header text-gray-500`}>{title}</Text>
        <Text style={tw`font-header leading-5 mt-2`}>{description}</Text>
      </View>
      <TouchableOpacity onPress={handlePress} activeOpacity={0.8}>
        <MaterialIcons name="info-outline" size={26} color="#105c1d" />
      </TouchableOpacity>
    </View>
  );
};
