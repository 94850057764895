import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { DEFAULT_HEADER_OPTIONS } from '../constants';
import { StayStackParamList } from '../types';
import { StayRoutes } from '../routes';
import { StaysScreen } from '../../../domain/stay/screens/Stays';

const Stack = createStackNavigator<StayStackParamList>();

export const StayStackNav = () => (
  <Stack.Navigator initialRouteName={StayRoutes.STAYS}>
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={StayRoutes.STAYS}
      component={StaysScreen}
    />
  </Stack.Navigator>
);
