import * as Linking from 'expo-linking';
import { LinkingOptions } from '@react-navigation/native';

import {
  BookRoutes,
  HomeRoutes,
  ProfileRoutes,
  RootRoutes,
  RootTabRoutes,
  StayRoutes,
} from './routes';
import { RootStackParamList } from './types';

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      [RootRoutes.LOGIN]: {
        path: 'login',
      },
      [RootRoutes.ROOT]: {
        path: '',
        screens: {
          [RootTabRoutes.HOME_ROOT]: {
            path: '',
            screens: {
              [HomeRoutes.HOME]: '',
            },
          },
          [RootTabRoutes.BOOK_ROOT]: {
            path: 'book',
            screens: {
              [BookRoutes.BOOK]: '',
            },
          },
          [RootTabRoutes.STAYS_ROOT]: {
            path: 'stays',
            screens: {
              [StayRoutes.STAYS]: '',
            },
          },
          [RootTabRoutes.PROFILE_ROOT]: {
            path: 'profile',
            screens: {
              [ProfileRoutes.PROFILE]: {
                path: '',
              },
              [ProfileRoutes.CONTACT_INFORMATION]: {
                path: 'info',
              },
              [ProfileRoutes.EDIT_INFORMATION]: {
                path: 'info/edit/:name',
              },
              [ProfileRoutes.ABOUT_APP]: {
                path: 'about',
              },
            },
          },
        },
      },
      [RootRoutes.STAY_DETAILS]: {
        path: 'stays/stay/:id/:type',
      },
      [RootRoutes.MODIFY_MY_STAY]: {
        path: 'stays/stay/:id',
      },
      [RootRoutes.NOT_FOUND]: {
        path: '*',
      },
    },
  },
};
