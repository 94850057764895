import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { BookScreen } from '../../../domain/book/screens/Book';
import { DEFAULT_HEADER_OPTIONS } from '../constants';
import { BookRoutes } from '../routes';
import { BookStackParamList } from '../types';

const Stack = createStackNavigator<BookStackParamList>();

export const BookStackNav = () => (
  <Stack.Navigator initialRouteName={BookRoutes.BOOK}>
    <Stack.Screen
      options={{
        headerShown: false,
        ...DEFAULT_HEADER_OPTIONS,
      }}
      name={BookRoutes.BOOK}
      component={BookScreen}
    />
  </Stack.Navigator>
);
