import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

export enum ButtonType {
  BACK = 'arrow-back',
  CLOSE = 'close',
  INFO = 'question',
}
interface Props {
  type: ButtonType;
}

export const HeaderLeft: React.FC<Props> = ({ type }) => {
  const { goBack } = useNavigation();

  return (
    <TouchableOpacity onPress={() => goBack()} activeOpacity={0.8}>
      <MaterialIcons name={type} color="black" size={26} />
    </TouchableOpacity>
  );
};
