import { Dimensions } from 'react-native';
import {
  DateStatuses,
  DateTypes,
  SeasonTypes,
  SelectedDates,
  Stay_Types_Enum,
} from '@hamlet/graphql-urql';
import { CalendarTypes } from '@hamlet/utils';

import { PolicyInfoTypes } from './types';

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const MAX_BODY_WIDTH = 1350;
export const ITEM_WIDTH = SCREEN_WIDTH > MAX_BODY_WIDTH ? MAX_BODY_WIDTH / 7 : SCREEN_WIDTH / 7;

export const DEFAULT_SELECTED_VALUE = {
  date: '',
  dateType: DateTypes.RegularDate,
  seasonType: SeasonTypes.RegularSeason,
  status: DateStatuses.Free,
  specialDateName: '',
  startingStayDay: false,
  endingStayDay: false,
  startingSpecialDay: false,
  endingSpecialDay: false,
};

export const DEFAULT_SELECTED: SelectedDates = {
  start: DEFAULT_SELECTED_VALUE,
  end: DEFAULT_SELECTED_VALUE,
};

export const MODAL_WINDOW_DATA: {
  [key in PolicyInfoTypes]: { title: string; description: string };
} = {
  cancelation: {
    title: 'Canceling less than 30 days ahead of stay',
    description:
      'If you cancel within 30 days ahead of arrival, these nights will count against your general stays, unless another owner re-book them.',
  },
  [Stay_Types_Enum.PeakSeasonStay]: {
    title: 'Active peak booking',
    description: 'You will be able to book additional Peak Season stays at Short Notice period.',
  },
  [Stay_Types_Enum.AdvanceStay]: {
    title: 'Advance stay booking',
    description:
      'Bookings during regular season without special days. These stays count towards your general stay count.',
  },
  [Stay_Types_Enum.PeakSpecialStay]: {
    title: 'Peak special stay booking',
    description:
      'This stay will count toward your special date stay count, as well as general stay count and peak booking count.',
  },
  [Stay_Types_Enum.ShortNoticeStay]: {
    title: 'Short notice stay booking',
    description: 'Short notice stays will not count towards your general stay count',
  },
  [Stay_Types_Enum.SpecialStay]: {
    title: 'Special stay booking',
    description:
      'Booking during regular season with special dates. This stay will count towards your special date stay count, and your general stay count.',
  },
};

export const POLICY_TITLES: {
  [key in PolicyInfoTypes]: string;
} = {
  cancelation: 'CANCELLATION POLICY',
  [Stay_Types_Enum.PeakSeasonStay]: 'PEAK SEASON BOOKING',
  [Stay_Types_Enum.AdvanceStay]: 'ADVANCE STAY BOOKING',
  [Stay_Types_Enum.PeakSpecialStay]: 'PEAK SPECIAL BOOKING',
  [Stay_Types_Enum.ShortNoticeStay]: 'SHORT NOTICE BOOKING',
  [Stay_Types_Enum.SpecialStay]: 'SPECIAL STAY BOOKING',
};

export const BOTTOM_BUTTON_TITLE = {
  [CalendarTypes.CREATE]: 'CONTINUE TO BOOK',
  [CalendarTypes.EDIT]: 'CONFIRM STAY CHANGES',
};
