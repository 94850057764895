import {
  GetCalendarDataByHouseIdDocument,
  GetHouseInfoInDateRangeByHouseIdDocument,
  GetHouseByIdQuery,
  GetUserLimitStatusDocument,
  GetUserByIdDocument,
  GetUserLimitStatusQuery,
  GetUserByIdQuery,
} from '@hamlet/graphql-urql';
import { START_CALENDAR_DATE, END_CALENDAR_DATE } from '@hamlet/utils';
import { GetCalendarDataByHouseIdQuery } from '@hamlet/graphql-request';
import { maskTypename } from 'urql';

import appConfig from '../config/appConfig';
import { UserAuthData } from '../hooks/auth0';
import { fetchRequest, RequestMethods } from '../hooks/fetchRequest';

export const convertUserAuthDataToUserInfo = (data: UserAuthData) => ({
  ...data,
  userId: data?.['https://hasura.io/jwt/claims']['x-hasura-user-id'] || '',
});

export const getHouseDataByHouseId = async (houseId: string, accessToken: string) => {
  const body = {
    query: GetHouseInfoInDateRangeByHouseIdDocument.loc?.source.body,
    variables: { house_id: houseId, start_date: START_CALENDAR_DATE, end_date: END_CALENDAR_DATE },
  };

  const { data } = await fetchRequest<{ data: GetHouseByIdQuery }>({
    endpoint: appConfig.hasuraUri,
    method: RequestMethods.POST,
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });

  return maskTypename(data?.houses_by_pk);
};

export const getUserLimits = async (userId: string, accessToken: string) => {
  const body = {
    query: GetUserLimitStatusDocument.loc?.source.body,
    variables: { userId },
  };

  const { data } = await fetchRequest<{ data: GetUserLimitStatusQuery }>({
    endpoint: appConfig.hasuraUri,
    method: RequestMethods.POST,
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });

  return maskTypename(data?.calculateStayLimits);
};

export const getCalendarData = async (house_id: string, user_id: string, accessToken: string) => {
  const body = {
    query: GetCalendarDataByHouseIdDocument.loc?.source.body,
    variables: { house_id, user_id, active_stay_id: '' },
  };

  const { data } = await fetchRequest<{ data: GetCalendarDataByHouseIdQuery }>({
    endpoint: appConfig.hasuraUri,
    method: RequestMethods.POST,
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });

  return maskTypename(data?.calendarData);
};

export const getUserData = async (id: string, accessToken: string) => {
  const body = {
    query: GetUserByIdDocument.loc?.source.body,
    variables: { id },
  };

  const { data } = await fetchRequest<{ data: GetUserByIdQuery }>({
    endpoint: appConfig.hasuraUri,
    method: RequestMethods.POST,
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });

  return maskTypename(data?.users_by_pk);
};
