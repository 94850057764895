import React from 'react';
import { View, Text } from 'react-native';
import { tw } from '../styles/tailwind';

interface ModalSectionProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  example?: JSX.Element;
}

export const ModalSectionElement: React.FC<ModalSectionProps> = ({
  title,
  description,
  icon,
  example,
}) => {
  return (
    <View style={tw`flex flex-col mb-6`}>
      <View style={tw`flex flex-row items-center`}>
        {icon}
        <View style={tw`flex flex-1 ml-3`}>
          <Text style={tw`text-lg font-header`}>{title}</Text>
          <Text style={tw`text-md font-header leading-5`}>{description}</Text>
        </View>
      </View>
      {example && <>{example}</>}
    </View>
  );
};
