import React from 'react';
import { Text, View } from 'react-native';
import dayjs from 'dayjs';

import { tw } from '../../../common/styles/tailwind';

interface Props {
  current: string;
}

const firstMonthOfYear = dayjs().startOf('year').format('MMMM').toLocaleUpperCase();

export const MonthHeader: React.FC<Props> = ({ current }) => {
  const month = dayjs(current).format('MMMM').toLocaleUpperCase();
  const showYear = firstMonthOfYear === month;

  return (
    <View style={tw`px-4 pb-6 flex-row w-full justify-between items-center items-center`}>
      <Text style={tw`text-base font-header font-medium`}>{month}</Text>
      {showYear && (
        <Text style={tw`text-base font-header font-medium`}>{dayjs(current).format('YYYY')}</Text>
      )}
    </View>
  );
};
