import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { tw } from '../styles/tailwind';
import { CustomTabBarLabel } from '../components/CustomTabBarLabel';
import { RootRoutes, RootTabRoutes } from './routes';
import { RootStackParamList, RootTabParamList } from './types';
import { HomeStackNav } from './home/HomeStackNav';
import { BookStackNav } from './book/BookStackNav';
import { StayStackNav } from './stay/StayStackNav';
import { ProfileStackNav } from './profile/ProfileStackNav';
import { useUserState } from '../state';

const barStyle = Platform.select({ web: 'bg-white h-px', default: 'bg-white' });
const Tab = createMaterialBottomTabNavigator<RootTabParamList>();

export const HomeTabNav = () => {
  const { reset } = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { userInfo, accessToken, deleteUserInfo } = useUserState();

  useEffect(() => {
    if (!accessToken) {
      reset({ index: 0, routes: [{ name: RootRoutes.LOGIN }] });
      deleteUserInfo();
    }
  }, [accessToken, deleteUserInfo, reset]);

  return (
    <Tab.Navigator
      initialRouteName={RootTabRoutes.HOME_ROOT}
      activeColor={tw`bg-activeTab`.backgroundColor as string}
      inactiveColor={tw`bg-inactiveTab`.backgroundColor as string}
      barStyle={tw`${barStyle}`}
    >
      <Tab.Screen
        name={RootTabRoutes.HOME_ROOT}
        component={HomeStackNav}
        options={{
          tabBarLabel: (<CustomTabBarLabel label="Home" />) as any,
          tabBarIcon: ({ color, focused }) => (
            <MaterialCommunityIcons
              name={focused ? 'home' : 'home-outline'}
              color={color}
              size={26}
            />
          ),
        }}
      />
      {userInfo?.house_id && userInfo?.house_share ? (
        <>
          <Tab.Screen
            name={RootTabRoutes.BOOK_ROOT}
            component={BookStackNav}
            options={{
              tabBarLabel: (<CustomTabBarLabel label="Book" />) as any,
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'calendar-blank' : 'calendar-blank-outline'}
                  color={color}
                  size={26}
                />
              ),
            }}
          />
          <Tab.Screen
            name={RootTabRoutes.STAYS_ROOT}
            component={StayStackNav}
            options={{
              tabBarLabel: (<CustomTabBarLabel label="Stays" />) as any,
              tabBarIcon: ({ color, focused }) => (
                <MaterialCommunityIcons
                  name={focused ? 'sofa' : 'sofa-outline'}
                  color={color}
                  size={24}
                />
              ),
            }}
          />
        </>
      ) : null}
      <Tab.Screen
        name={RootTabRoutes.PROFILE_ROOT}
        component={ProfileStackNav}
        options={{
          tabBarLabel: (<CustomTabBarLabel label="Profile" />) as any,
          tabBarIcon: ({ color, focused }) => (
            <FontAwesome name={focused ? 'user-circle' : 'user-circle-o'} color={color} size={23} />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
