import React from 'react';
import { View, Text } from 'react-native';
import { SelectedDates } from '@hamlet/graphql-urql';
import { formatDate } from '@hamlet/utils';

import { tw } from '../../../common/styles/tailwind';

interface Props {
  selected: SelectedDates;
}

const DateInfoColumn = ({ title, date }: { title: string; date: string }) => (
  <View>
    <Text style={tw`font-bold font-header text-gray-500 text-xs`}>{title}</Text>
    <Text style={tw`font-header`}>{date}</Text>
  </View>
);

export const DatesInfo: React.FC<Props> = ({ selected }) => {
  const formatedStartDate = formatDate(selected.start.date, 'dddd, DD MMM');
  const formatedEndDate = formatDate(selected.end.date, 'dddd, DD MMM');

  return (
    <View
      style={tw`w-full max-w-3xl justify-between items-center mb-4 pb-4 border-b-1 border-gray-300 flex flex-row`}
    >
      <DateInfoColumn title="ARRIVE 12:00 PM" date={formatedStartDate} />
      <View style={tw`h-0.5 w-6 bg-gray-300`} />
      <DateInfoColumn title="DEPART 12:00 PM" date={formatedEndDate} />
    </View>
  );
};
