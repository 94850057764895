import React, { useMemo, useState } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Stay_Statuses_Enum,
  Stay_Types_Enum,
  useUpdateStayByIdMutation,
} from '@hamlet/graphql-urql';
import { SafeAreaView } from 'react-native-safe-area-context';
import {
  formatDate,
  SUCCESS_MESSAGES,
  DAYS_WITHOUT_PENALTY,
  STAY_DATE_FORMAT,
  CURRENT_DATE,
} from '@hamlet/utils';
import dayjs from 'dayjs';

import { RootParamList } from '../../../common/routes/types';
import { useToast } from '../../../common/hooks/useToast';
import { RootRoutes } from '../../../common/routes/routes';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { CustomModal } from '../../../common/components/CustomModal';
import { tw } from '../../../common/styles/tailwind';
import { useUserState } from '../../../common/state';
import { StayTabs } from '../types';
import { Header } from '../../../common/components/Header';
import { ButtonType } from '../../../common/components/HeaderLeftButton';
import { useCalendarData } from '../../../common/hooks/useCalendarData';
import { Layout } from '../../../common/components/Layout';

export const StayDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { params } = useRoute<RouteProp<RootParamList, RootRoutes.STAY_DETAILS>>();
  const { houseInfo } = useUserState((state) => state);
  const { navigate, goBack } = useNavigation<StackNavigationProp<RootParamList>>();
  const [, updateStayById] = useUpdateStayByIdMutation();
  const [visible, changeVisible] = useState(false);
  const upcomingStay = params.type === StayTabs.UPCOMING;
  const { refreshCalendarData } = useCalendarData();

  const stay = useMemo(() => {
    return (houseInfo?.stays || []).find((el) => el.id === params.id);
  }, [houseInfo]);

  if (!stay) {
    return <ScreenLoader />;
  }

  const arriveDate = formatDate(stay.start_date, STAY_DATE_FORMAT);
  const departDate = formatDate(stay.end_date, STAY_DATE_FORMAT);
  const daysToStartDate = dayjs(stay.start_date).diff(CURRENT_DATE, 'days');
  const shortNoticeStay = stay.type === Stay_Types_Enum.ShortNoticeStay;
  const withoutPenalty = daysToStartDate >= DAYS_WITHOUT_PENALTY || shortNoticeStay;
  const description = withoutPenalty
    ? 'These nights will not count against your annual stay nights'
    : 'If other owners do not book these nights, they will count against your annual stay nights';
  const refundedStay = stay.status === Stay_Statuses_Enum.Refunded;

  const showModal = () => {
    changeVisible(true);
  };

  const hideModal = () => {
    changeVisible(false);
  };

  const handleCancelStay = async () => {
    setIsLoading(true);
    const status = withoutPenalty ? Stay_Statuses_Enum.Refunded : Stay_Statuses_Enum.Canceled;

    hideModal();
    await updateStayById({ id: params.id, stay: { status } });
    await refreshCalendarData();
    setIsLoading(false);
    goBack();
    toast.success(SUCCESS_MESSAGES.cancelStay);
  };

  const handleModify = () => {
    navigate(RootRoutes.MODIFY_MY_STAY, { id: params.id });
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`}>
      {isLoading && <ScreenLoader />}
      <Header title="Stay details" leftButtonType={ButtonType.BACK} />
      <Layout scrollable webStyles="shadow-lg">
        <CustomModal
          visible={visible}
          handleLeftButton={hideModal}
          handleRightButton={handleCancelStay}
          leftButtonText="GO BACK"
          rightButtonText="YES, CANCEL"
          title="Are you sure you want to cancel your stay?"
          description={description}
        />
        <View style={tw`flex flex-row p-4 border-b-1 border-gray-400`}>
          <View style={tw`flex-1`}>
            <Text style={tw`mb-2.5 text-gray-600 font-header`}>ARRIVE</Text>
            <Text style={tw`font-header`}>{arriveDate}</Text>
          </View>
          <View style={tw`flex-1`}>
            <Text style={tw`mb-2.5 text-gray-600 font-header`}>DEPART</Text>
            <Text style={tw`font-header`}>{departDate}</Text>
          </View>
          {upcomingStay && (
            <TouchableOpacity onPress={handleModify} style={tw`ml-auto`} activeOpacity={0.8}>
              <Text style={tw`font-medium text-primary text-sm text-center font-header`}>
                MODIFY
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {refundedStay && (
          <View style={tw`flex flex-row p-4 border-b-1 border-gray-400`}>
            <View style={tw`flex-1`}>
              <Text style={tw`mb-2.5 text-gray-600 font-header`}>STATUS</Text>
              <Text style={tw`font-header`}>Refunded</Text>
            </View>
          </View>
        )}
        {upcomingStay && (
          <TouchableOpacity style={tw`mt-4`} onPress={showModal} activeOpacity={0.8}>
            <Text style={tw`text-center text-lg text-rose-700 font-medium font-header`}>
              CANCEL STAY
            </Text>
          </TouchableOpacity>
        )}
      </Layout>
    </SafeAreaView>
  );
};
