import { useUserState } from '../state';
import { getCalendarData, getHouseDataByHouseId, getUserLimits } from '../utils/utils';

export const useCalendarData = () => {
  const { accessToken, userInfo, setHouseInfo, setCalendarData, setUserLimits } = useUserState();

  const refreshCalendarData = async () => {
    if (!accessToken || !userInfo?.house_id || !userInfo.id) {
      return;
    }

    const [houseInfo, calendarData, userLimitStatus] = await Promise.all([
      getHouseDataByHouseId(userInfo.house_id, accessToken),
      getCalendarData(userInfo.house_id, userInfo.id, accessToken),
      getUserLimits(userInfo.id, accessToken),
    ]);

    setUserLimits(userLimitStatus);

    if (houseInfo) {
      setHouseInfo(houseInfo);
    }

    if (calendarData) {
      setCalendarData(calendarData);
    }
  };

  return { refreshCalendarData };
};
