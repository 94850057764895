import React from 'react';
import { Modal, TouchableOpacity, View, Text } from 'react-native';

import { tw } from '../styles/tailwind';

interface Props {
  visible: boolean;
  handleLeftButton: () => void;
  handleRightButton?: () => void;
  title: string;
  description: string;
  leftButtonText: string;
  rightButtonText?: string;
}

const buttonStyle =
  'p-3 border-1 flex flex-1 justify-center items-center border-gray-400 rounded-md';

export const CustomModal: React.FC<Props> = ({
  visible,
  handleLeftButton,
  handleRightButton,
  title,
  description,
  leftButtonText,
  rightButtonText,
}) => (
  <Modal transparent={true} visible={visible} animationType="fade">
    <View style={tw`flex h-full items-center justify-center px-4 bg-[rgba(0,0,0,0.5)]`}>
      <View style={tw`p-5 rounded-md bg-white min-h-[250px] max-w-web w-full`}>
        <Text style={tw`text-lg font-base font-medium pb-4`}>{title}</Text>
        <Text style={tw`pb-10 font-header text-gray-600 text-md leading-5`}>{description}</Text>

        <View style={tw`flex-row mt-auto flex justify-between`}>
          <TouchableOpacity
            style={tw`${buttonStyle} mr-1`}
            onPress={handleLeftButton}
            activeOpacity={0.8}
          >
            <Text style={tw`text-center font-header text-md text-black font-medium`}>
              {leftButtonText}
            </Text>
          </TouchableOpacity>
          {rightButtonText && (
            <TouchableOpacity
              style={tw`${buttonStyle} ml-1`}
              onPress={handleRightButton}
              activeOpacity={0.8}
            >
              <Text style={tw`text-center font-header text-md text-primary font-medium`}>
                {rightButtonText}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  </Modal>
);
