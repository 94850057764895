import { IMessageOptions } from '../components/Messages';
import { useMessageState } from '../state';

export const useToast = () => {
  const setInfoMessage = useMessageState((state) => state.setInfoMessage);
  const setSuccessMessage = useMessageState((state) => state.setSuccessMessage);
  const setWarningMessage = useMessageState((state) => state.setWarningMessage);
  const setDangerMessage = useMessageState((state) => state.setDangerMessage);

  const toast = {
    info: (message: string, description?: string, options?: IMessageOptions) =>
      setInfoMessage(message, description, options),
    success: (message: string, description?: string, options?: IMessageOptions) =>
      setSuccessMessage(message, description, options),
    warning: (message: string, description?: string, options?: IMessageOptions) =>
      setWarningMessage(message, description, options),
    danger: (message: string, description?: string, options?: IMessageOptions) =>
      setDangerMessage(message, description, options),
  };

  return toast;
};
