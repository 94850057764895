import React, { useRef } from 'react';
import { SafeAreaView, StyleSheet, Dimensions } from 'react-native';
import { isWeb } from '../constants';
import { ActivityIndicator } from 'react-native';
import LottieView from 'lottie-react-native';

interface Props {
  color?: string;
  size?: 'small' | 'large';
}

const { width, height } = Dimensions.get('window');

export const ScreenLoader: React.FC<Props> = ({ color = '#478429', size = 'large' }) => {
  const animation = useRef(null);

  return (
    <SafeAreaView style={styles.container}>
      {isWeb ? (
        <ActivityIndicator color={color} size={size} />
      ) : (
        <LottieView
          autoPlay
          ref={animation}
          style={styles.animation}
          source={require('../../../assets/loader.json')}
        />
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    width: width,
    height: height,
    zIndex: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  animation: {
    width: 130,
    height: 130,
    backgroundColor: 'transparent',
  },
  loadingText: {
    fontSize: 20,
    marginTop: 0,
    fontFamily: 'font-header',
  },
});
