import React, { useEffect } from 'react';
import { BottomSheetBackdrop, BottomSheetModal } from '@gorhom/bottom-sheet';

import { BottomSheetHeader } from './BottomSheetHeader';
import { tw } from '../styles/tailwind';

interface CustomBottomSheetModalProps {
  isVisible: boolean;
  closeModal: () => void;
  children: JSX.Element;
  snapPoints: string[];
  title: string;
}

export const CustomBottomSheetModal: React.FC<CustomBottomSheetModalProps> = ({
  isVisible,
  closeModal,
  children,
  snapPoints,
  title,
}) => {
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);

  useEffect(() => {
    if (isVisible) {
      bottomSheetModalRef.current?.present();
    } else {
      bottomSheetModalRef.current?.dismiss();
    }
  }, [isVisible]);

  return (
    <BottomSheetModal
      backdropComponent={BottomSheetBackdrop}
      handleComponent={() => <BottomSheetHeader title={title} onClose={closeModal} />}
      ref={bottomSheetModalRef}
      index={1}
      onDismiss={closeModal}
      snapPoints={snapPoints}
      style={tw`max-w-web w-full mx-auto shadow-lg`}
    >
      {children}
    </BottomSheetModal>
  );
};
