import React from 'react';
import { Route, TabBar, TabBarProps } from 'react-native-tab-view';

import { tw } from '../../../common/styles/tailwind';

export const CustomTabBar: React.FC<TabBarProps<Route>> = (props) => (
  <TabBar
    {...props}
    indicatorStyle={tw`bg-white`}
    style={tw`bg-primary`}
    labelStyle={tw`font-header`}
  />
);
