import React from 'react';
import { View } from 'react-native';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { tw } from '../../../common/styles/tailwind';
import BookingRulesElement from './BookingRulesElement';

interface Props {
  closeModal: () => void;
  isVisible: boolean;
}

const BookingRulesModal: React.FC<Props> = ({ closeModal, isVisible }) => {
  return (
    <CustomBottomSheetModal
      closeModal={closeModal}
      isVisible={isVisible}
      snapPoints={['50%', '95%']}
      title="Booking rules"
    >
      <Layout
        scrollable={true}
        contentContainerStyle={isWeb ? tw`items-center` : tw`pb-[40px]`}
        commonStyles="px-4"
      >
        <View style={tw`w-full`}>
          <BookingRulesElement
            title="General stays"
            titleTag="6 per share"
            description="Booked 31 days to 24 months in advance. Owners may hold up to the maximum general
          stays at a time. Each 7-night stay counts as 1 general stay"
          />

          <BookingRulesElement
            title="Special date stay"
            titleTag="1 per share"
            description="You can hold 1 special date stay at a time. This includes major federal holidays and notable local events. Special dates are included in your general stay holds."
          />
          <BookingRulesElement
            title="Peak season stay"
            titleTag="2 per share"
            description="You can hold 2 peak season stays for each peak season. Peak season opens for booking 24 months in advance. Each 7-night stay counts as 1 peak season stay."
          />
          <BookingRulesElement
            title="Short notice stay"
            titleTag="Unlimited"
            description="Calendar dates 2 - 30 days in advance. Stays within this timeframe will not count toward your stay count."
          />
          <BookingRulesElement
            title="Minimum stay length"
            description="A general stay must be at least 2 nights. A special date stay must be at least 3 nights."
          />
          <BookingRulesElement
            title="Back-to-back stay"
            description="To ensure ongoing access for all owners, the period between your arrival date and your prior departure must be equal to or greater than the number of nights of your last stay."
          />
          <BookingRulesElement
            title="Year-to-year booking"
            description="You can not book the same special dates or peak season dates from year to year when you have an active stay on those dates."
          />
          <BookingRulesElement
            title="Modifying existing stay"
            description="If you would like to change the dates of a stay you've booked and the dates are available, you can modify your stay in the &Hamlet app."
          />
          <BookingRulesElement
            title="Canceling a stay"
            description="Owners can cancel with no penalty 30 or more days prior to arrival. Cancellations will trigger a notification to other owners alerting them of the newly available dates. If the dates are rebooked by another owner, there is no penalty for the canceling owner. If the dates are not rebooked, the dates count toward the canceling owner's anual stay nights for the year."
          />
        </View>
      </Layout>
    </CustomBottomSheetModal>
  );
};

export default BookingRulesModal;
