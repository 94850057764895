import { Stay_Types_Enum } from '@hamlet/graphql-urql';

import { StayTabs } from './types';

export const stayType: { [key in Stay_Types_Enum]: string } = {
  [Stay_Types_Enum.AdvanceStay]: 'advance stay',
  [Stay_Types_Enum.PeakSeasonStay]: 'peak season stay',
  [Stay_Types_Enum.PeakSpecialStay]: 'peak special stay',
  [Stay_Types_Enum.ShortNoticeStay]: 'short notice stay',
  [Stay_Types_Enum.SpecialStay]: 'special stay',
};

export const TAB_ROUTES = [
  { key: StayTabs.UPCOMING, title: StayTabs.UPCOMING },
  { key: StayTabs.PAST, title: StayTabs.PAST },
  { key: StayTabs.CANCELED, title: StayTabs.CANCELED },
];
