import { StackNavigationOptions } from '@react-navigation/stack';

export const DEFAULT_HEADER_OPTIONS: StackNavigationOptions = {
  headerTitleStyle: {
    color: 'black',
    fontSize: 26,
  },
  headerStyle: {
    backgroundColor: '#fff',
  },
  headerTitleAlign: 'center',
};
