import React from 'react';
import { View, Text } from 'react-native';
import { useForm } from 'react-hook-form';

import { tw } from '../../../../common/styles/tailwind';
import { Header } from '../../../../common/components/Header';
import { ButtonType } from '../../../../common/components/HeaderLeftButton';
import { useUserState } from '../../../../common/state';
import { InfoBlockWrapper } from '../../../home/components/InfoBlockWrapper';
import { SwitchInput } from '../../../../common/components/SwitchInput';
import { FormFieldsData } from '../../../../common/types/types';
import { UserFieldNames } from '../../types';
import { Layout } from '../../../../common/components/Layout';

interface Props {
  handleSave: (user: FormFieldsData, resetFields: (user: FormFieldsData) => void) => Promise<void>;
}

export const SettingsForm: React.FC<Props> = ({ handleSave }) => {
  const { userInfo } = useUserState();
  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<FormFieldsData>({
    defaultValues: { notifications_enabled: userInfo?.notifications_enabled || false },
  });

  const handlePress = async (user: FormFieldsData) => {
    await handleSave(user, reset);
  };

  return (
    <>
      <Header
        leftButtonType={ButtonType.BACK}
        title={UserFieldNames.SETTINGS}
        rightIcon={
          <Text style={tw`font-header ${isDirty ? 'text-black' : 'text-gray-400'}`}>Save</Text>
        }
        disabledRightButton={!isDirty}
        showRightIcon
        onPressRightIcon={handleSubmit(handlePress)}
      />
      <Layout scrollable webStyles="shadow-lg">
        <InfoBlockWrapper>
          <Text style={tw`mb-3 font-header`}>NOTIFICATIONS</Text>
          <View style={tw`flex flex-row justify-between items-center`}>
            <Text style={tw`font-header`}>Receive email messages</Text>
            <SwitchInput name="notifications_enabled" control={control} />
          </View>
        </InfoBlockWrapper>
      </Layout>
    </>
  );
};
