import React from 'react';
import { FlatList, TouchableOpacity, View, Text } from 'react-native';
import { Route } from 'react-native-tab-view';
import { LimitStatus, StayFragment } from '@hamlet/graphql-urql';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import dayjs from 'dayjs';

import { stayType } from '../constants';
import { ListHeader } from './ListHeader';
import { RootRoutes } from '../../../common/routes/routes';
import { tw } from '../../../common/styles/tailwind';
import { RootParamList } from '../../../common/routes/types';
import { ListFooter } from './ListFooter';
import { Stay_Statuses_Enum } from '@hamlet/graphql-urql';

interface Props {
  data: StayFragment[];
  route: Route;
  userLimits: LimitStatus | null;
}

export const TabViewList: React.FC<Props> = ({ data, route, userLimits }) => {
  const keyExtractor = (item: any) => `stay-${item.id}`;

  const { navigate } = useNavigation<StackNavigationProp<RootParamList>>();

  const renderItem = ({ item }: { item: StayFragment; index: number }) => {
    const arriveDay = dayjs(item.start_date).format('DD');
    const arriveYear = dayjs(item.start_date).format('YYYY');
    const arriveMonth = dayjs(item.start_date).format('MMM').toLocaleUpperCase();
    const difference = dayjs(item.end_date).diff(item.start_date, 'days');

    const handlePress = () => {
      navigate(RootRoutes.STAY_DETAILS, { id: item.id, type: route.key });
    };

    return (
      <TouchableOpacity
        onPress={handlePress}
        style={tw`flex-row px-4 items-center py-4 border-t-1 border-b-1 border-gray-300 mb-4 justify-between`}
        activeOpacity={0.8}
      >
        <View style={tw`flex-row items-center`}>
          <View style={tw`text-center`}>
            <Text style={tw`text-xl font-header font-medium text-center text-gray-500`}>
              {arriveDay}
            </Text>
            <Text style={tw`text-xs font-header text-gray-500`}>{arriveMonth}</Text>
            <Text style={tw`text-gray-500 font-header text-xs`}>{arriveYear}</Text>
          </View>
          <View style={tw`pl-4`}>
            <Text style={tw`text-sm font-header font-medium text-primary uppercase`}>
              {stayType[item.type]}
            </Text>
            <Text style={tw`text-base font-header`}>{difference} nights</Text>
            {item.status !== Stay_Statuses_Enum.Booked && (
              <Text style={tw`font-xs font-bold font-header`}>
                {item.status === Stay_Statuses_Enum.Canceled ? 'Canceled' : 'Refunded'}
              </Text>
            )}
          </View>
        </View>
        <Text style={tw`text-lg`}>
          <MaterialIcons name="chevron-right" color="#6a6969" size={26} />
        </Text>
      </TouchableOpacity>
    );
  };

  const listHeader = () => <ListHeader title={route.key} />;
  const listFooter = () => <ListFooter routeKey={route.key} userLimits={userLimits} />;

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListHeaderComponent={listHeader}
      ListFooterComponent={listFooter}
      contentContainerStyle={tw`pt-2.5 mb-24`}
    />
  );
};
