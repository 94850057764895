import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Dimensions } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { TabView } from 'react-native-tab-view';
import { StayFragment, Stay_Statuses_Enum } from '@hamlet/graphql-urql';
import { CALENDAR_DATE_FORMAT, CURRENT_DATE } from '@hamlet/utils';
import { SimpleLineIcons } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';

import { Header } from '../../../common/components/Header';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import { RootTabRoutes } from '../../../common/routes/routes';
import { isWeb } from '../../../common/constants';
import { CustomTabBar } from '../components/CustomTabBar';
import { TabViewList } from '../components/TabViewList';
import { TAB_ROUTES } from '../constants';
import { StayTabs } from '../types';
import BookingRulesModal from '../../book/components/BookingRulesModal';
import HelpButton from '../../../common/components/HelpButton';

const { width: SCREEN_WIDTH } = Dimensions.get('window');

export const StaysScreen = () => {
  const [index, setIndex] = React.useState(0);
  const houseInfo = useUserState((state) => state.houseInfo);
  const userInfo = useUserState((state) => state.userInfo);
  const userLimits = useUserState((state) => state.userLimits);
  const [isBookingRuleModalVisible, setIsBookingRuleModalVisible] = useState(false);

  const toggleCalendarInfoModal = () => {
    setIsBookingRuleModalVisible((prev) => !prev);
  };

  const data = useMemo(() => {
    const stays = (houseInfo?.stays || []).filter((stay) => {
      return stay.user_id === userInfo?.id;
    });

    return stays.reduce<{ [key in StayTabs]: StayFragment[] }>(
      (acc, stay) => {
        const endDate = dayjs(stay.end_date).format(CALENDAR_DATE_FORMAT);
        const booked = stay.status === Stay_Statuses_Enum.Booked;
        const pastStay = endDate < CURRENT_DATE;
        const upcomingStay = endDate >= CURRENT_DATE;

        if (upcomingStay && booked) {
          acc.upcoming = [...acc.upcoming, stay];
          return acc;
        }

        if (pastStay && booked) {
          acc.past = [...acc.past, stay];
          return acc;
        }

        acc.canceled = [...acc.canceled, stay];
        return acc;
      },
      { upcoming: [], past: [], canceled: [] }
    );
  }, [houseInfo?.stays]);

  const renderScene = ({ route }: { route: { key: StayTabs } }) => (
    <TabViewList data={data[route.key]} route={route} userLimits={userLimits} />
  );

  return (
    <Animatable.View
      useNativeDriver
      duration={1000}
      animation="fadeIn"
      style={tw`w-full ${isWeb ? 'pb-10 h-full' : 'h-full'} bg-white`}
    >
      <SafeAreaView style={tw`w-full h-full bg-white`}>
        <Header
          title="Stays"
          showNavigationButtons={isWeb}
          activeButtonRoute={RootTabRoutes.STAYS_ROOT}
          showAllButtons={userInfo?.house_id && userInfo?.house_share}
          rightIcon={<SimpleLineIcons name="question" color="black" size={26} />}
          onPressRightIcon={toggleCalendarInfoModal}
        />
        {isWeb && <HelpButton onPress={toggleCalendarInfoModal} />}
        <BookingRulesModal
          isVisible={isBookingRuleModalVisible}
          closeModal={() => setIsBookingRuleModalVisible(false)}
        />
        <TabView
          renderTabBar={CustomTabBar}
          style={isWeb ? tw`max-w-web w-full shadow-lg bg-white self-center` : tw`w-full bg-white`}
          navigationState={{ index, routes: TAB_ROUTES }}
          initialLayout={{ width: SCREEN_WIDTH }}
          animationEnabled={false}
          renderScene={renderScene}
          onIndexChange={setIndex}
        />
      </SafeAreaView>
    </Animatable.View>
  );
};
