import React from 'react';
import { Dimensions, FlatList, Image } from 'react-native';

import { tw } from '../../../common/styles/tailwind';

const DATA = [
  'https://wallpaperaccess.com/full/4722343.jpg',
  'https://wallpaperaccess.com/full/1218437.jpg',
  'https://wallpaperaccess.com/full/4722322.jpg',
];

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const MAX_BODY_WIDTH = 1350;
const HORIZONTAL_PADDINGS = SCREEN_WIDTH > MAX_BODY_WIDTH ? SCREEN_WIDTH - MAX_BODY_WIDTH : 0;
const IMAGE_WIDTH = SCREEN_WIDTH - HORIZONTAL_PADDINGS;

export const ImageComponent: React.FC = () => {
  const renderItem = ({ item }: { item: string }) => (
    <Image source={{ uri: item }} style={tw`w-[${IMAGE_WIDTH}px] h-72`} />
  );

  const keyExtractor = (item: string, idx: number) => `house-image-${idx}`;

  return (
    <FlatList
      data={DATA}
      pagingEnabled
      horizontal
      showsHorizontalScrollIndicator={false}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
    />
  );
};
