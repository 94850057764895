module.exports = {
  theme: {
    extend: {
      colors: {
        primary: '#478429',
        secondary: '#E5EFFF',
        thrid: '#FFFDE6',
        fourth: '#000000',
        bookedDate: '#315c1c',
        activeTab: 'black',
        inactiveTab: '#626060',
      },
      fontFamily: {
        base: 'Feijoa',
        header: 'Styrene',
        signature: 'Styrene',
      },
      maxWidth: {
        web: '1024px',
      },
    },
    borderWidth: {
      1: '1px',
    },
  },
};
