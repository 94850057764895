import jwtDecode from 'jwt-decode';
import { TokenResponseConfig, TokenResponse, RefreshTokenRequestConfig } from 'expo-auth-session';
import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';

import appConfig from '../config/appConfig';
import { UserInfo } from '../state/types';
import { convertUserAuthDataToUserInfo } from '../utils/utils';
import { Platform } from 'react-native';

export interface UserAuthData extends UserInfo {
  'https://hasura.io/jwt/claims': {
    'x-hasura-user-id': string;
  };
}

const useProxy = false;
const getRedirectUrlBasedOnMobilePlatform = () => {
  const domainSplit = appConfig.auth0Domain.split('https://')[1];
  if (Platform.OS === 'android') {
    return `hamlet://${domainSplit}/android/com.andhamlet.hamlet/callback`;
  }
  if (Platform.OS === 'ios') {
    return `hamlet://${domainSplit}/ios/com.andhamlet.hamlet/callback`;
  }
  return AuthSession.makeRedirectUri({ useProxy });
};

const redirectUri = getRedirectUrlBasedOnMobilePlatform();

const authRequestConfig = {
  redirectUri,
  clientId: appConfig.auth0ClientId,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  extraParams: {
    resource: appConfig.hasuraUri,
    audience: `${appConfig.auth0Domain}/api/v2/`,
    access_type: 'offline',
  },
};

export const useAuth0 = () => {
  const [request, , promptAsync] = AuthSession.useAuthRequest(authRequestConfig, {
    authorizationEndpoint: `${appConfig.auth0Domain}/authorize`,
  });

  const login = async () => {
    const result = await promptAsync({ useProxy });

    if (!result || result.type !== 'success' || !result.params.code) {
      throw new Error('Something went wrong.');
    }

    const exchangeCodeConfig = {
      code: result.params.code,
      redirectUri,
      clientId: appConfig.auth0ClientId,
      extraParams: {
        code_verifier: request?.codeVerifier || '',
      },
    };

    const codeRes: TokenResponse = await AuthSession.exchangeCodeAsync(exchangeCodeConfig, {
      tokenEndpoint: `${appConfig.auth0Domain}/oauth/token`,
    });

    const tokenConfig: TokenResponseConfig = codeRes?.getRequestConfig();

    if (!tokenConfig.accessToken || !tokenConfig.idToken) {
      throw new Error('Something went wrong');
    }

    const userAuthData: UserAuthData = jwtDecode(tokenConfig.idToken);
    const tokenData = convertUserAuthDataToUserInfo(userAuthData);

    return {
      tokenData,
      accessToken: tokenConfig.accessToken,
      tokenConfig: JSON.stringify(tokenConfig),
    };
  };

  const logout = async () => {
    const res = await WebBrowser.openAuthSessionAsync(
      `${appConfig.auth0Domain}/v2/logout?client_id=${appConfig.auth0ClientId}&returnTo=${redirectUri}`,
      redirectUri
    );

    return res;
  };

  return { login, logout };
};

export const getActualTokenData = async (config: TokenResponseConfig) => {
  let tokenResponse = new TokenResponse(config);
  const tokenExpired = tokenResponse.shouldRefresh();

  if (tokenExpired) {
    const refreshConfig: RefreshTokenRequestConfig = {
      clientId: appConfig.auth0ClientId,
      refreshToken: config.refreshToken,
    };
    const endpointConfig: Pick<AuthSession.DiscoveryDocument, 'tokenEndpoint'> = {
      tokenEndpoint: `${appConfig.auth0Domain}/oauth/token`,
    };

    tokenResponse = await tokenResponse.refreshAsync(refreshConfig, endpointConfig);
  }

  const tokenConfig = JSON.stringify(tokenResponse.getRequestConfig());
  const userAuthData: UserAuthData = jwtDecode(tokenResponse?.idToken || '');
  const tokenData = convertUserAuthDataToUserInfo(userAuthData);

  return { tokenData, accessToken: tokenResponse.accessToken, tokenConfig };
};
