import * as Haptics from 'expo-haptics';

import { isWeb } from '../constants';

export const useHaptic = () => {
  const haptic = {
    success: () => !isWeb && Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light),
    error: () => !isWeb && Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error),
  };

  return haptic;
};
