import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import FlashMessage, { showMessage, hideMessage } from 'react-native-flash-message';

import { useMessageState } from '../state';
import { tw } from '../styles/tailwind';

export enum MessageType {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export interface IMessage {
  message: string;
  description?: string;
  type: MessageType;
  options?: IMessageOptions;
}

export interface IMessageOptions {
  autoHide?: boolean;
}

export const Messages = () => {
  const message = useMessageState((state) => state.message);
  const clearMessage = useMessageState((state) => state.clearMessage);

  useEffect(() => {
    if (!message || !message.message) {
      hideMessage();
    } else {
      showMessage({
        message: message.message,
        description: message.description,
        type: message.type,
        icon: message.type,
        titleStyle: tw`font-header`,
        autoHide: true || message?.options?.autoHide, // TODO: use message.option.autoHide
        onPress: clearMessage,
      });
    }
  }, [message, clearMessage]);

  return (
    <FlashMessage position="top" textStyle={tw`pr-8`} statusBarHeight={StatusBar.currentHeight} />
  );
};
