import React from 'react';
import { View, ScrollViewProps, ScrollView } from 'react-native';
import classNames from 'classnames';

import { tw } from '../styles/tailwind';
import { isWeb } from '../constants';

interface Props extends ScrollViewProps {
  scrollable?: boolean;
  withShadow?: boolean;
  children: React.ReactNode;
  webStyles?: string;
  mobileStyles?: string;
  commonStyles?: string;
}

export const Layout: React.FC<Props> = ({
  scrollable = false,
  withShadow = false,
  webStyles = '',
  mobileStyles = '',
  commonStyles = '',
  children,
  ...rest
}) => {
  const containerStyle = classNames(
    'bg-white mx-auto w-full',
    {
      'shadow-lg': withShadow,
      'max-w-web': isWeb,
    },
    commonStyles,
    isWeb ? webStyles : mobileStyles
  );

  return scrollable ? (
    <ScrollView style={tw`${containerStyle}`} {...rest}>
      <View>{children}</View>
    </ScrollView>
  ) : (
    <View style={tw`${containerStyle}`} {...rest}>
      {children}
    </View>
  );
};
