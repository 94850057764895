import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { HomeScreen } from '../../../domain/home/screens/Home';
import { DEFAULT_HEADER_OPTIONS } from '../constants';
import { HomeStackParamList } from '../types';
import { HomeRoutes } from '../routes';

const Stack = createStackNavigator<HomeStackParamList>();

export const HomeStackNav = () => (
  <Stack.Navigator initialRouteName={HomeRoutes.HOME}>
    <Stack.Screen
      options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
      name={HomeRoutes.HOME}
      component={HomeScreen}
    />
  </Stack.Navigator>
);
