import React from 'react';
import { View, Text } from 'react-native';
import { LimitStatus } from '@hamlet/graphql-urql';

import { StayTabs } from '../types';
import { tw } from '../../../common/styles/tailwind';

interface Props {
  routeKey: string;
  userLimits: LimitStatus | null;
}

export const ListFooter: React.FC<Props> = ({ routeKey, userLimits }) => {
  if (routeKey !== StayTabs.UPCOMING || !userLimits) {
    return null;
  }

  return (
    <View
      style={tw`flex-row px-4 items-center py-4 border-t-1 border-b-1 border-gray-300 mb-4 justify-between`}
    >
      <View>
        <Text style={tw`text-sm font-header font-medium uppercase text-gray-500`}>Stay limits</Text>
        <View style={tw`flex flex-row`}>
          <Text style={tw`font-header`}>
            {userLimits.advanceStaysBooked}/{userLimits.advanceStaysLimit} Stays
          </Text>
          <Text> · </Text>
          <Text style={tw`font-header`}>
            {userLimits.specialDatesBooked}/{userLimits.specialDatesLimit} Special Dates
          </Text>
          <Text> · </Text>
          <Text style={tw`font-header`}>
            {userLimits.daysBooked}/{userLimits.daysLimit} Days
          </Text>
        </View>
      </View>
    </View>
  );
};
