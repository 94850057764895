import React from 'react';
import { View, Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';
import { ITEM_WIDTH } from '../constants';

const DAYS = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

export const CalendarHeader = () => {
  const renderItem = (title: string, index: number) => (
    <Text
      style={{ ...tw`w-full text-center font-semibold`, maxWidth: ITEM_WIDTH }}
      key={`day-${index}`}
    >
      {title}
    </Text>
  );

  return <View style={tw`py-3.5 flex-row bg-gray-200 w-full`}>{DAYS.map(renderItem)}</View>;
};
