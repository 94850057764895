import { Stay_Statuses_Enum } from '@hamlet/graphql-urql';
import { CalendarTypes } from '@hamlet/utils';
import React, { useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as Animatable from 'react-native-animatable';

import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import { Header } from '../../../common/components/Header';
import { ScreenLoader } from '../../../common/components/ScreenLoader';
import { useCalendarData } from '../../../common/hooks/useCalendarData';
import { RootTabRoutes } from '../../../common/routes/routes';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import { Calendar } from '../components/Calendar';

import BookingRulesModal from '../components/BookingRulesModal';
import { isWeb } from '../../../common/constants';
import CalendarHelpModal from '../components/CalendarHelpModal';
import { useHaptic } from '../../../common/hooks/useHaptic';

export const BookScreen = () => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isRuleModalVisible, setIsRuleModalVisible] = useState(false);
  const { userInfo, houseInfo, calendarData, userLimits } = useUserState();
  const allHouseSharesSold = houseInfo?.shares_total === houseInfo?.sold_shares;
  const houseShare = userInfo?.house_share;

  if (!userInfo || !houseShare) {
    return <ScreenLoader />;
  }

  const { refreshCalendarData } = useCalendarData();
  const stays = React.useMemo(
    () => (houseInfo?.stays || []).filter((stay) => stay.status === Stay_Statuses_Enum.Booked),
    [houseInfo?.stays]
  );

  const toggleCalendarInfoModal = () => {
    useHaptic().success();
    setIsInfoModalVisible((prev) => !prev);
  };

  const openStayRules = () => {
    setIsInfoModalVisible(false);
    setIsRuleModalVisible((prev) => !prev);
  };

  return (
    <Animatable.View
      style={tw`w-full h-full bg-white`}
      useNativeDriver
      duration={1000}
      animation="fadeIn"
    >
      <SafeAreaView style={tw`w-full h-full bg-white`}>
        <Header
          title="Book"
          rightIcon={<SimpleLineIcons name="question" color="black" size={26} />}
          onPressRightIcon={toggleCalendarInfoModal}
          showNavigationButtons={isWeb}
          activeButtonRoute={RootTabRoutes.BOOK_ROOT}
          showAllButtons={userInfo?.house_id && userInfo?.house_share}
        />

        <CalendarHelpModal
          isInfoModalVisible={isInfoModalVisible}
          closeModal={(value) => setIsInfoModalVisible(value)}
          openStayRules={openStayRules}
        />

        <BookingRulesModal
          isVisible={isRuleModalVisible}
          closeModal={() => setIsRuleModalVisible(false)}
        />

        <Calendar
          stays={stays}
          userInfo={userInfo}
          calendarData={calendarData}
          allHouseSharesSold={allHouseSharesSold}
          toggleCalendarInfoModal={toggleCalendarInfoModal}
          refreshCalendarData={refreshCalendarData}
          specialDates={houseInfo?.special_dates || []}
          peakSeasons={houseInfo?.peak_seasons || []}
          calendarType={CalendarTypes.CREATE}
          limitStatus={userLimits}
          maxGeneralStays={houseShare.general_stays}
          maxStayLength={houseShare.max_stay_length}
          shares={houseShare.shares}
        />
      </SafeAreaView>
    </Animatable.View>
  );
};
