import React from 'react';
import { TextInput } from 'react-native';
import { Control, useController } from 'react-hook-form';

import { FormFieldsData } from '../types/types';
import { tw } from '../styles/tailwind';

interface Props {
  name: string;
  control: Control<FormFieldsData, any>;
}

export const Input: React.FC<Props> = ({ name, control }) => {
  const { field } = useController({ control, name: name as any });

  return (
    <TextInput
      style={tw`font-header rounded-lg border-1 p-3`}
      value={field.value}
      onChangeText={field.onChange}
    />
  );
};
