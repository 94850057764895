import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { LoginScreen } from '../../domain/auth/screens/Login';
import { StayModifyScreen } from '../../domain/book/screens/StayModify';
import { StayDetail } from '../../domain/stay/screens/StayDetail';
import { NotFoundScreen } from '../screens/NotFound';
import { useUserState } from '../state';
import { DEFAULT_HEADER_OPTIONS } from './constants';
import { HomeTabNav } from './HomeTabNav';
import { RootRoutes } from './routes';
import { linking } from './linkingConfiguration';

const Stack = createStackNavigator();

export const Navigator = () => {
  let initialRoute = RootRoutes.LOGIN;
  const { accessToken, userInfo } = useUserState();

  if (userInfo?.given_name && userInfo?.email && userInfo?.picture && accessToken) {
    initialRoute = RootRoutes.ROOT;
  }

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName={initialRoute}>
        <Stack.Screen
          options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
          name={RootRoutes.LOGIN}
          component={LoginScreen}
        />
        {accessToken ? (
          <>
            <Stack.Screen
              options={{ headerShown: false }}
              name={RootRoutes.ROOT}
              component={HomeTabNav}
            />
            <Stack.Screen
              options={{
                headerShown: false,
                ...DEFAULT_HEADER_OPTIONS,
              }}
              name={RootRoutes.STAY_DETAILS}
              component={StayDetail}
            />
            <Stack.Screen
              options={{
                headerShown: false,
                presentation: 'modal',
                ...DEFAULT_HEADER_OPTIONS,
              }}
              name={RootRoutes.MODIFY_MY_STAY}
              component={StayModifyScreen}
            />
            <Stack.Screen
              options={{ headerShown: false, ...DEFAULT_HEADER_OPTIONS }}
              name={RootRoutes.NOT_FOUND}
              component={NotFoundScreen}
            />
          </>
        ) : null}
      </Stack.Navigator>
    </NavigationContainer>
  );
};
