import {
  DateStatuses,
  DateTypes,
  LimitStatus,
  MarkedDates,
  SeasonTypes,
  SelectedDates,
  Stay_Types_Enum,
  UnavailableDate,
  UnavailableDateTypes,
} from '@hamlet/graphql-urql';
import {
  ActiveStayLimits,
  convertNightsToDays,
  CURRENT_DATE,
  dateIsBeetween,
  GENERAL_STAY_LENGTH,
  getLastShortNoticeDate,
} from '@hamlet/utils';
import classNames from 'classnames';

import { tw } from '../../common/styles/tailwind';
import { ITEM_WIDTH, POLICY_TITLES } from './constants';
import { PolicyInfoTypes } from './types';

export const getCalendarDayStyle = ({
  marking,
  isCurrentDay,
  unavailableDate,
  allHouseSharesSold,
}: {
  marking: MarkedDates;
  isCurrentDay: boolean;
  unavailableDate: UnavailableDate | undefined;
  allHouseSharesSold: boolean;
}) => {
  const bookedByOwner = marking.status === DateStatuses.BookedByOwner;
  const booked = marking.status === DateStatuses.Booked;
  const editable = marking.status === DateStatuses.Editable;
  const unavailable = unavailableDate?.type === UnavailableDateTypes.ActiveUniqueStay;
  const dateBeforeCurrentDate = CURRENT_DATE > marking.date;

  const lastEstimatedShortStayDate = getLastShortNoticeDate(allHouseSharesSold);
  const shortNotice = marking.date <= lastEstimatedShortStayDate;

  const containerStyles = classNames(`w-full h-14 justify-center flex items-center`, {
    'bg-secondary': marking.seasonType === SeasonTypes.PeakSeason,
  });

  const bodyStyles = classNames('w-full h-10 flex items-center justify-center', {
    'rounded-l-3xl': marking.startingSpecialDay,
    'rounded-r-3xl': marking.endingSpecialDay,
    'border-l-1 ml-1': marking.startingSpecialDay && !bookedByOwner,
    'border-r-1 mr-1': marking.endingSpecialDay && !bookedByOwner,
    'border-t-1 border-b-1': marking.dateType === DateTypes.SpecialDate,
  });

  const wrapperStyles = classNames('w-full h-10 justify-center flex items-center', {
    'bg-bookedDate': bookedByOwner && !dateBeforeCurrentDate,
    'bg-thrid': bookedByOwner && dateBeforeCurrentDate,
    'bg-primary': editable,
    'rounded-l-3xl': (bookedByOwner || editable) && marking.startingStayDay,
    'rounded-r-3xl': (bookedByOwner || editable) && marking.endingStayDay,
    'ml-1': (bookedByOwner || editable) && marking.startingStayDay && !marking.startingSpecialDay,
    'mr-1': (bookedByOwner || editable) && marking.endingStayDay && !marking.endingSpecialDay,
  });

  const textStyles = classNames({
    'text-white': (bookedByOwner || editable) && !isCurrentDay,
    'line-through font-bold text-gray-300': unavailable && !shortNotice,
    'line-through text-gray-300': booked && !isCurrentDay,
    'text-red-400 font-bold': isCurrentDay,
  });

  return {
    container: { ...tw`${containerStyles}`, maxWidth: ITEM_WIDTH },
    body: tw`${bodyStyles}`,
    wrapper: tw`${wrapperStyles}`,
    text: tw`${textStyles}`,
  };
};

export const getButtonsStatus = ({
  selected,
  startDate,
  endDate,
}: {
  selected: SelectedDates;
  startDate: string;
  endDate: string;
}) => {
  const startDateSelected = Boolean(selected.start.date);
  const endDateSelected = Boolean(selected.end.date);
  const selectedBothDates = startDateSelected && endDateSelected;
  const onlyStartDateSelected = startDateSelected && !endDateSelected;

  const startDateNotUnique = selected.start.date === startDate;
  const endDateNotUnique = selected.end.date === endDate;
  const startDateUnique = startDateSelected && !startDateNotUnique;
  const endDateUnique = endDateSelected && !endDateNotUnique;
  const bothDatesUnique = selectedBothDates && !startDateNotUnique && !endDateNotUnique;

  const resetButtonVisible =
    onlyStartDateSelected || startDateUnique || bothDatesUnique || endDateUnique;
  const bottomButtonVisible = selectedBothDates && (!startDateNotUnique || !endDateNotUnique);

  return { resetButtonVisible, bottomButtonVisible };
};

const getPeakSeason = ({
  selected,
  limitStatus,
}: {
  selected: SelectedDates;
  limitStatus: LimitStatus;
}) => {
  return limitStatus.peakSeasonStays.find((peakSeason) => {
    if (!peakSeason) return;

    const startDateInPeakSeason = dateIsBeetween(
      selected.start.date,
      peakSeason.peakSeasonStart,
      peakSeason.peakSeasonEnd,
      '[]'
    );

    const endDateInPeakSeason = dateIsBeetween(
      selected.end.date,
      peakSeason.peakSeasonStart,
      peakSeason.peakSeasonEnd,
      '[]'
    );

    return startDateInPeakSeason || endDateInPeakSeason;
  });
};

const getBookingDescription = ({
  type,
  limitStatus,
  selected,
  maxStayLength,
  stayNigths,
  activeStayLimits,
}: {
  type: PolicyInfoTypes;
  limitStatus: LimitStatus | null;
  selected: SelectedDates;
  maxStayLength: number;
  stayNigths: number;
  activeStayLimits: ActiveStayLimits;
}) => {
  if (!limitStatus || !selected.end.date || !selected.start.date) {
    return '';
  }

  const peakSeasonLimitPerPeakSeason = maxStayLength / GENERAL_STAY_LENGTH;
  const peakSeason = getPeakSeason({ selected, limitStatus });
  const bookedStaysInThisPeakSeason = peakSeason?.peakSeasonGeneralStays
    ? peakSeason.peakSeasonGeneralStays - activeStayLimits.peakSeason
    : 0;

  const generalStays = Math.ceil(stayNigths / GENERAL_STAY_LENGTH);
  const generalDays = stayNigths && convertNightsToDays(stayNigths);
  const activeStayDays = activeStayLimits.nights && convertNightsToDays(activeStayLimits.nights);
  const generalDaysAvailableLimit = limitStatus.daysLimit - limitStatus.daysBooked + activeStayDays;
  const advanceAvailableLimit =
    limitStatus.advanceStaysLimit - limitStatus.advanceStaysBooked + activeStayLimits.advance;
  const specialAvailableLimit =
    limitStatus.specialDatesLimit - limitStatus.specialDatesBooked + activeStayLimits.special;
  const peakSeasonAvailableLimit = peakSeasonLimitPerPeakSeason - bookedStaysInThisPeakSeason;

  const commonLimitEnding = `${generalStays} of ${advanceAvailableLimit} available general stays \n${generalDays} of ${generalDaysAvailableLimit} available general days`;

  switch (true) {
    case type === Stay_Types_Enum.PeakSeasonStay:
      return `You will use: \n${generalStays} of ${peakSeasonAvailableLimit} available stays in this peak season \n${commonLimitEnding}`;
    case type === Stay_Types_Enum.AdvanceStay:
      return `You will use: \n${commonLimitEnding}`;
    case type === Stay_Types_Enum.PeakSpecialStay:
      return `You will use: \n${generalStays} of ${peakSeasonAvailableLimit} available stays in this peak season \n${specialAvailableLimit} of ${specialAvailableLimit} available special stays \n${commonLimitEnding}`;
    case type === Stay_Types_Enum.ShortNoticeStay:
      return 'This stay will not count towards your stay limits';
    case type === Stay_Types_Enum.SpecialStay: {
      return `You will use: \n${specialAvailableLimit} of ${specialAvailableLimit} available special stays \n${commonLimitEnding}`;
    }
    default:
      return '';
  }
};

export const getBookingDetails = ({
  stayType,
  selected,
  limitStatus,
  maxStayLength,
  stayDuration,
  activeStayLimits,
}: {
  stayType: Stay_Types_Enum;
  selected: SelectedDates;
  limitStatus: LimitStatus | null;
  maxStayLength: number;
  stayDuration: number;
  activeStayLimits: ActiveStayLimits;
}) => {
  const title = POLICY_TITLES[stayType];
  const description = getBookingDescription({
    type: stayType,
    limitStatus,
    selected,
    maxStayLength,
    stayNigths: stayDuration || 0,
    activeStayLimits,
  });

  return { title, description };
};
