export enum ProfileIconNames {
  CHEVRON_RIGHT = 'chevron-right',
  LOGOUT = 'logout',
  EDIT = 'edit',
  PRIVACY = 'privacy-tip',
  TERMS = 'contact-page',
}

export enum UserFieldNames {
  NAME = 'Name',
  PHONE = 'Phone',
  COUNTRY = 'Country',
  SETTINGS = 'App settings',
}
