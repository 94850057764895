import React from 'react';
import { TouchableOpacity, Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';

interface Props {
  isVisible: boolean;
  handlePress: () => void;
}

export const TopButton: React.FC<Props> = ({ isVisible, handlePress }) => {
  const display = isVisible ? 'flex' : 'hidden';

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={tw`z-10 absolute right-4 top-[70px] px-4 py-2 rounded-3xl border-1 bg-white border-primary ${display}`}
      activeOpacity={0.8}
    >
      <Text style={tw`font-medium text-primary`}>RESET</Text>
    </TouchableOpacity>
  );
};
