import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Switch } from 'react-native';

import { FormFieldsData } from '../types/types';

interface Props {
  name: string;
  control: Control<FormFieldsData, any>;
}

export const SwitchInput: React.FC<Props> = ({ name, control }) => {
  const { field } = useController({ control, name: name as any });

  return <Switch onValueChange={field.onChange} value={field.value} />;
};
