import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { tw } from '../styles/tailwind';

interface Props {
  title: string;
  onClose: () => void;
}

export const BottomSheetHeader: React.FC<Props> = ({ title, onClose }) => (
  <View style={tw`flex-row justify-between p-4 items-center`}>
    <Text style={tw`text-xl font-medium font-header`}>{title}</Text>
    <TouchableOpacity onPress={onClose} activeOpacity={0.8}>
      <MaterialIcons name="close" color="black" size={26} />
    </TouchableOpacity>
  </View>
);
