import React, { useState } from 'react';
import { Text, TouchableOpacity, View, Linking } from 'react-native';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import * as Animatable from 'react-native-animatable';

import { CustomBottomSheetModal } from '../../../common/components/CustomBottomSheetModal';
import { Header } from '../../../common/components/Header';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { RootTabRoutes } from '../../../common/routes/routes';
import { useUserState } from '../../../common/state';
import { tw } from '../../../common/styles/tailwind';
import { HorizontalLine } from '../../profile/components/HorizontalLine';
import { ImageComponent } from '../components/ImageComponent';
import { InfoBlockWrapper } from '../components/InfoBlockWrapper';
import { ContactInformation } from '../components/ContactInformation';

enum BottomSheetType {
  COMPANY = 'company',
  MANAGER = 'manager',
}

const COMPANY_INFO = { title: '&Hamlet Team', phone: '99999999', email: 'hei@hamlet.no' };

export const HomeScreen = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userLimits, userInfo, houseInfo } = useUserState((state) => state);
  const [bottomSheetInfo, changeBottomSheetInfo] = useState(COMPANY_INFO);
  const isOwner = userInfo?.house_id && userInfo.house_share && userLimits;

  if (!isOwner) {
    return <ContactInformation />;
  }

  const handleShowContacts = (type: BottomSheetType) => {
    let info = COMPANY_INFO;

    if (type === BottomSheetType.MANAGER) {
      info = {
        title: 'Manager',
        phone: String(houseInfo?.house_manager.phone_number) || '',
        email: houseInfo?.house_manager.email || '',
      };
    }

    changeBottomSheetInfo(info);
    setIsModalVisible(true);
  };

  return (
    <Animatable.View
      useNativeDriver
      duration={1000}
      animation="fadeIn"
      style={tw`w-full ${isWeb ? 'pb-10 h-full' : 'h-full'} bg-white`}
    >
      {isWeb && (
        <Header
          title="Home"
          showNavigationButtons
          activeButtonRoute={RootTabRoutes.HOME_ROOT}
          showAllButtons={userInfo?.house_id && userInfo?.house_share}
        />
      )}
      <CustomBottomSheetModal
        closeModal={() => setIsModalVisible(false)}
        isVisible={isModalVisible}
        snapPoints={['20%', '40%']}
        title={bottomSheetInfo.title}
      >
        <View style={tw`px-4`}>
          {bottomSheetInfo?.phone && (
            <View style={tw`flex-row items-center mb-3`}>
              <MaterialIcons name="phone" size={26} color="black" />
              <TouchableOpacity onPress={() => Linking.openURL(`tel:${bottomSheetInfo.phone}`)}>
                <Text style={tw`ml-2 text-lg font-header underline`}>{bottomSheetInfo.phone}</Text>
              </TouchableOpacity>
            </View>
          )}
          {bottomSheetInfo?.email && (
            <View style={tw`flex-row items-center mt-2`}>
              <MaterialIcons name="mail" size={26} color="black" />
              <TouchableOpacity onPress={() => Linking.openURL(`mailto:${bottomSheetInfo.email}`)}>
                <Text style={tw`ml-2 text-lg font-header underline`}>{bottomSheetInfo.email}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </CustomBottomSheetModal>
      <Layout
        scrollable
        withShadow
        contentContainerStyle={tw`pb-10`}
        showsVerticalScrollIndicator={false}
      >
        <ImageComponent />
        {houseInfo?.name && houseInfo?.description && (
          <InfoBlockWrapper>
            <View style={tw`flex-row justify-between items-center mb-3`}>
              <Text style={tw`text-lg font-base`}>{houseInfo.name}</Text>
            </View>
            {houseInfo?.shares_total && (
              <View style={tw`mt-3`}>
                <Text style={tw`mb-3 font-header`}>YOUR SHARE</Text>
                <Text style={tw`font-bold text-black font-header`}>
                  1/{houseInfo.shares_total} of this property
                </Text>
              </View>
            )}
            <HorizontalLine />
          </InfoBlockWrapper>
        )}
        {houseInfo?.country && houseInfo?.location && houseInfo?.address && (
          <InfoBlockWrapper>
            <Text style={tw`mb-3 font-header`}>LOCATION</Text>
            <Text
              style={tw`font-bold font-header`}
            >{`${houseInfo.country}, ${houseInfo.location}, ${houseInfo.address}`}</Text>
            <HorizontalLine />
          </InfoBlockWrapper>
        )}
        <InfoBlockWrapper>
          <Text style={tw`mb-3 font-header`}>CONTACTS</Text>
          {(houseInfo?.house_manager.phone_number || houseInfo?.house_manager.email) && (
            <View style={tw`mb-5`}>
              <View style={tw`flex-row justify-between`}>
                <Text style={tw`font-bold font-header`}>Home Manager</Text>
                <TouchableOpacity
                  onPress={() => handleShowContacts(BottomSheetType.MANAGER)}
                  activeOpacity={0.8}
                >
                  <Text style={tw`text-primary font-bold font-header`}>CONTACT</Text>
                </TouchableOpacity>
              </View>
              <HorizontalLine />
            </View>
          )}
          <View style={tw`flex-row justify-between mt-2`}>
            <Text style={tw`font-bold font-header`}>Hamlet Team</Text>
            <TouchableOpacity
              onPress={() => handleShowContacts(BottomSheetType.COMPANY)}
              activeOpacity={0.8}
            >
              <Text style={tw`text-primary font-bold font-header`}>CONTACT</Text>
            </TouchableOpacity>
          </View>
          <HorizontalLine />
        </InfoBlockWrapper>
        <InfoBlockWrapper>
          <Text style={tw`mb-3 font-header`}>YOUR STAY COUNT</Text>
          <View style={tw`flex flex-col`}>
            <Text style={tw`font-bold font-header`}>
              {userLimits.advanceStaysBooked}/{userLimits.advanceStaysLimit} stays
            </Text>
            <HorizontalLine />
            <Text style={tw`font-bold font-header mt-4`}>
              {userLimits.specialDatesBooked}/{userLimits.specialDatesLimit} special dates
            </Text>
            <HorizontalLine />
            <Text style={tw`font-bold font-header mt-4`}>
              {userLimits.daysBooked}/{userLimits.daysLimit} days
            </Text>
            <HorizontalLine />
          </View>
        </InfoBlockWrapper>
      </Layout>
    </Animatable.View>
  );
};
