import React from 'react';
import { Linking, Text, TouchableOpacity, View, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as WebBrowser from 'expo-web-browser';

import { Header } from '../../../common/components/Header';
import { Layout } from '../../../common/components/Layout';
import { isWeb } from '../../../common/constants';
import { RootTabRoutes } from '../../../common/routes/routes';
import { tw } from '../../../common/styles/tailwind';

export const ContactInformation = () => {
  const openWebLink = async (link: string) => {
    try {
      await WebBrowser.openBrowserAsync(link);
    } catch (error) {}
  };

  return (
    <SafeAreaView style={tw`w-full h-full bg-white`}>
      <Header
        title="Home"
        showNavigationButtons={isWeb}
        activeButtonRoute={RootTabRoutes.HOME_ROOT}
      />
      <Layout
        scrollable
        withShadow
        contentContainerStyle={tw`p-4`}
        showsVerticalScrollIndicator={false}
      >
        <View style={tw`text-center flex flex-col items-center`}>
          <View style={tw`w-full flex items-center`}>
            <Image
              source={require('../../../../assets/hamlet_logo_full.png')}
              resizeMode="contain"
              style={tw`w-[${isWeb ? '30%' : '80%'}] h-[90px] mb-5`}
            />
          </View>
          <Text style={tw`font-header text-xl text-center`}>
            To get all the available functionality of the application, you must own a &Hamlet.
          </Text>
          <Text style={tw`font-header text-xl mt-5 text-center`}>
            If you own a &Hamlet, and see this screen, please contact us at
          </Text>
          <TouchableOpacity onPress={() => Linking.openURL('mailto:hei@andhamlet.no')}>
            <Text style={{ ...tw`font-bold font-header text-lg underline` }}>
              {' '}
              hei@andhamlet.no
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => openWebLink('https://andhamlet.com')}
            style={tw`bg-primary p-4 text-center w-[${isWeb ? '40%' : '100%'}] mt-5`}
          >
            <Text style={tw`font-header text-lg text-white text-center`}>
              Learn more about &Hamlet
            </Text>
          </TouchableOpacity>
        </View>
      </Layout>
    </SafeAreaView>
  );
};
