import React from 'react';
import { Text } from 'react-native';

import { tw } from '../../../common/styles/tailwind';

interface Props {
  title: string;
}

export const ListHeader: React.FC<Props> = ({ title }) => (
  <Text style={tw`text-center font-base text-gray-500 text-lg mb-5`}>{`Your ${title} stays`}</Text>
);
