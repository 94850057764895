import { MarkedDates } from '@hamlet/graphql-urql';
import React from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import { Style } from 'twrnc/dist/esm/types';
import { isWeb } from '../../../common/constants';

interface Props {
  day: string;
  data: MarkedDates;
  style: { container: Style; body: Style; wrapper: Style; text: Style };
  onPress: (data: MarkedDates) => void;
}

export const CalendarDay: React.FC<Props> = ({ day, data, style, onPress }) => {
  const handlePress = () => onPress(data);

  return (
    <TouchableOpacity
      activeOpacity={isWeb ? 0.7 : 0.2}
      delayPressIn={0}
      onPress={handlePress}
      style={style.container}
    >
      <View style={style.body}>
        <View style={style.wrapper}>
          <Text style={style.text}>{day}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};
