import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import classNames from 'classnames';

import { RootTabRoutes } from '../routes/routes';
import { tw } from '../styles/tailwind';

const navigationTitles: { [key in RootTabRoutes]: string } = {
  [RootTabRoutes.HOME_ROOT]: 'Home',
  [RootTabRoutes.BOOK_ROOT]: 'Book',
  [RootTabRoutes.STAYS_ROOT]: 'Stays',
  [RootTabRoutes.PROFILE_ROOT]: 'Profile',
};

interface Props {
  type: RootTabRoutes;
  activeButtonRoute: RootTabRoutes;
  onPress: (type: RootTabRoutes) => void;
}

export const NavigationHeaderButton: React.FC<Props> = ({ type, activeButtonRoute, onPress }) => {
  const active = activeButtonRoute === type;

  const handlePress = () => {
    onPress(type);
  };

  const textStyle = classNames('hover:text-primary', {
    'text-primary': active,
    'text-gray-500': !active,
  });

  return (
    <TouchableOpacity onPress={handlePress} style={tw`mx-5`}>
      <Text style={tw`${textStyle}`}>{navigationTitles[type]}</Text>
    </TouchableOpacity>
  );
};
